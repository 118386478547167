import { ApolloClient, HttpLink, split } from '@apollo/client';
import fetch from 'isomorphic-unfetch';
// import { onError } from 'apollo-link-error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { createCache } from './cache';
import { getMainDefinition } from '@apollo/client/utilities';
// import https from 'https';

// eslint-disable-next-line no-undef
// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
// let accessToken = null
//
// const requestAccessToken = async () => {
//     if (accessToken) return
//
//     const res = await fetch(`${process.env.APP_HOST}/api/session`)
//     if (res.ok) {
//         const json = await res.json()
//         accessToken = json.accessToken
//     } else {
//         accessToken = 'public'
//     }
// }

// remove cached token on 401 from the server
// const resetTokenLink = onError(({ networkError }) => {
//     if (networkError && networkError.name === 'ServerError' && networkError.statusCode === 401) {
//         accessToken = null
//     }
// })
// eslint-disable-next-line no-undef
// process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = 0;

const createHttpLink = () => {
  const httpLink = new HttpLink({
    // eslint-disable-next-line no-undef
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
      ? // eslint-disable-next-line no-undef
        'https://' + process.env.REACT_APP_GRAPHQL_ENDPOINT
      : 'https://apps.solaareogunministries.org/backend/graphql',
    agentOptions: {
      rejectUnauthorized: false
    },
    credentials: 'include',
    // agent: new https.Agent({ rejectUnauthorized: false }),
    fetch
  });
  return httpLink;
};

const createWSLink = () => {
  return new WebSocketLink(
    new SubscriptionClient(
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT
        ? // eslint-disable-next-line no-undef
          'wss://' + process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT
        : 'wss://apps.solaareogunministries.org/backend/websocket',
      {
        lazy: true,
        reconnect: true
        // connectionParams: async () => {
        //     await requestAccessToken() // happens on the client
        //     return {
        //         headers: {
        //             authorization: accessToken ? `Bearer ${accessToken}` : '',
        //         },
        //     }
        // },
      }
    )
  );
};

export default function createApolloClient() {
  const cache = createCache();
  let link;
  const wsLink = createWSLink(); // executed on server
  const httpLink = createHttpLink(); // executed on server

  link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );
  // link = createWSLink() // executed on client

  return new ApolloClient({
    link,
    cache
  });
}
