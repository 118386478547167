/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Input } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  SendBirdProvider,
  OpenChannel,
  withSendBird,
  sendBirdSelectors
} from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';

function CustomInput(props) {
  const [value, setValue] = useState('');
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();

  return (
    <Input
      color="teal"
      placeholder={
        isAuthenticated
          ? `Chat publicly as ${user.name.split(/@| /)[0]}`
          : `Login to chat publicly`
      }
      value={value}
      disabled={!isAuthenticated}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyUp={(e) => {
        if (e.key !== 'Enter') {
          return;
        }

        const params = new props.sdk.UserMessageParams();
        params.message = value;

        props
          .sendMessage(process.env.REACT_APP_CHANNEL_ID, params)
          .then((message) => {
            console.log('message -------', message);
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    />
  );
}

const CustomInputWithSendBird = withSendBird(CustomInput, (state) => {
  const sendMessage = sendBirdSelectors.getOpenChannelSendUserMessage(state);
  const sdk = sendBirdSelectors.getSdk(state);

  return {
    sendMessage,
    sdk
  };
});

const Chat = (props) => {
  return (
    <SendBirdProvider
      appId={process.env.REACT_APP_CHAT_APP_ID}
      userId={props.user}
      nickname={props.user}
      profileUrl={props.pp}
      theme="dark"
      allowProfileEdit={true}>
      <div style={{ height: '50vh' }}>
        <OpenChannel
          useMessageGrouping={false} // To determine whether to use message grouping,
          // disableUserProfile
          channelUrl={process.env.REACT_APP_CHANNEL_ID}
          renderMessageInput={(channel, user, disabled) => {
            return (
              <CustomInputWithSendBird
                channel={channel}
                user={user}
                disabled={disabled}
              />
            );
          }}
        />
      </div>
    </SendBirdProvider>
  );
};

export default withSendBird(Chat);
