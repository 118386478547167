import { Routes, Route } from 'react-router-dom';
import { LayoutWithPlayer } from 'presentation/components';
import OnAir from './OnAir';
import Podcast from './Podcast';
import Feedback from './Feedback';
import Forms from './Forms';

function App() {
  return (
    <div>
      <LayoutWithPlayer>
        <Routes>
          <Route path="/" element={<OnAir />} />
          <Route path="podcast" element={<Podcast />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="forms" element={<Forms />} />
          <Route path="*" element={<OnAir />} />
        </Routes>
      </LayoutWithPlayer>
    </div>
  );
}

export default App;
