import PropTypes from 'prop-types';
import { Box, Flex, Text, CloseButton } from '@chakra-ui/react';
import { FiRadio, FiMessageSquare, FiFileText } from 'react-icons/fi';
import NavItem from '../NavItem';

const LinkItems = [
  { name: 'OnAir', icon: FiRadio },
  // { name: 'Podcast', icon: FiMic }, // page is not ready
  { name: 'Forms', icon: FiFileText },
  { name: 'Feedback', icon: FiMessageSquare }
];

const Navigation = ({ onClose, ...rest }) => {
  return (
    <Box
      bg={'#232950'}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text color={'white'} fontSize="2xl" fontWeight="bold">
          DCiRadio
        </Text>
        <CloseButton
          color="white"
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
        />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          color={'white'}
          key={link.name}
          icon={link.icon}
          onClose={onClose}
          to={link.name.toLocaleLowerCase()}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};
export default Navigation;

Navigation.propTypes = {
  onClose: PropTypes.func.isRequired
};
