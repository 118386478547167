import PropTypes from 'prop-types';
import {
  IconButton,
  Flex,
  useColorModeValue,
  Text,
  VStack,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Button
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';

const MobileNav = ({ onOpen, ...rest }) => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('#232950')}
      color="white"
      boxShadow={'2xl'}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        color={'#b8c1ec'}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        color={'#b8c1ec'}
        fontWeight="medium">
        DCiRadio
      </Text>
      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          {(() => {
            if (isAuthenticated) {
              return (
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}>
                    <HStack>
                      <Avatar
                        size={'sm'}
                        name={user?.name || ''}
                        src={user?.picture || ''}
                        alt={user?.name || 'profile'}
                      />
                      <VStack
                        display={{ base: 'none', md: 'flex' }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2">
                        <Text fontSize="sm">{user?.name || ''}</Text>
                      </VStack>
                      <Box display={{ base: 'none', md: 'flex' }}>
                        <FiChevronDown />
                      </Box>
                    </HStack>
                  </MenuButton>
                  <MenuList bg="#3F4094" borderStyle="none">
                    <MenuItem
                      onClick={() => {
                        logout({ returnTo: window.location.origin });
                      }}
                      _hover={{
                        bg: '#3F4094',
                        color: 'white'
                      }}>
                      Sign out
                    </MenuItem>
                  </MenuList>
                </Menu>
              );
            } else {
              return (
                <>
                  <Button
                    onClick={() => loginWithRedirect()}
                    aria-label="Login"
                    bg="#3F4094"
                    color="#fff"
                    _hover={{
                      bg: '#3F4094',
                      color: 'white'
                    }}
                    variant="solid">
                    Login
                  </Button>
                </>
              );
            }
          })()}
        </Flex>
      </HStack>
    </Flex>
  );
};

MobileNav.propTypes = {
  onOpen: PropTypes.func.isRequired
};

export default MobileNav;
