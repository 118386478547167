import { Heading, Box, Text, Flex, Stack, IconButton } from '@chakra-ui/react';
import { FiPlay, FiPause } from 'react-icons/fi';
import millify from 'millify';
import PropTypes from 'prop-types';
import { useAudioPlayer } from 'react-use-audio-player';
import moment from 'moment';

function Card({ primary, data, stateChanger }) {
  const { load, play, pause, loading, playing } = useAudioPlayer({
    autoplay: true
  });
  return (
    <Box
      maxW={'320px'}
      h={'380px'}
      w={'full'}
      bgGradient={'linear(to-b, #3F4094, #01AEED)'}
      boxShadow={'2xl'}
      rounded={'lg'}
      color={'#FFF'}
      textAlign="left"
      p={6}>
      <Flex h={'full'} flexDirection={'column'} justifyContent={'flex-end'}>
        <Heading
          textTransform="uppercase"
          fontWeight="medium"
          fontSize={'2xl'}
          as="h2"
          size="4xl"
          mb={4}
          isTruncated>
          {data?.name || 'DC'}
        </Heading>
        <Text fontWeight="light" fontSize="1xl" isTruncated>
          {data?.currentStream?.title || 'Dream Center Internet Radio'}
        </Text>
        <Text fontSize="xs" isTruncated>
          {moment().format('Do MMM, YYYY')}
        </Text>
        <Stack
          mt={6}
          direction={'row'}
          justifyContent="space-between"
          spacing={4}
          align={'center'}>
          <IconButton
            colorScheme="blackAlpha"
            aria-label="Play Radio"
            size="lg"
            isLoading={loading}
            fontSize="20px"
            isDisabled={loading}
            isRound={true}
            onClick={() => {
              if (!primary) {
                load({
                  src: data.url,
                  autoplay: true,
                  html5: true,
                  format: ['mp3']
                });
                stateChanger(data);
              } else {
                if (playing) {
                  pause();
                } else {
                  play();
                }
              }
            }}
            icon={!primary ? <FiPlay /> : playing ? <FiPause /> : <FiPlay />}
          />
          <Stack spacing={0} align={'center'}>
            <Text fontWeight={600}>
              {millify(data?.currentStream?.viewers || 0)}
            </Text>
            <Text fontSize={'sm'}>Listeners</Text>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
}

export default Card;

Card.propTypes = {
  data: PropTypes.object,
  primary: PropTypes.bool.isRequired,
  stateChanger: PropTypes.func
};
