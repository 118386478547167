import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Icon
} from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi';

import { useForm } from '@formspree/react';

function Feedback() {
  const [state, handleSubmit] = useForm('xpzbdnbe');

  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden">
      <Flex>
        <Box
          bg="#b8c1ec"
          color="black"
          borderRadius="md"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={8}>
          <Wrap spacing={{ base: 10, sm: 3, md: 5, lg: 10 }}>
            <Box>
              <Heading
                fontWeight="medium"
                fontSize={'2xl'}
                as="h2"
                size="xl"
                mb={4}>
                Feedback
              </Heading>
              <Text mt={{ sm: 3, md: 3, lg: 5 }}>
                We want to hear from you, tell us what you think
              </Text>
            </Box>

            <Box bg="white" p={4} color="#0B0E3F" borderRadius="md">
              {!state.succeeded ? (
                <form onSubmit={handleSubmit}>
                  <VStack spacing={3}>
                    <FormControl id="name">
                      <FormLabel htmlFor="name">Full name</FormLabel>

                      <Input name="name" type="text" size="md" />
                    </FormControl>
                    <FormControl id="email">
                      <FormLabel htmlFor="email">Email address</FormLabel>
                      <Input name="email" type="email" />
                    </FormControl>
                    <FormControl id="message">
                      <FormLabel htmlFor="message">Feedback</FormLabel>
                      <Textarea
                        name="message"
                        borderColor="gray.300"
                        _hover={{
                          borderRadius: 'gray.300'
                        }}
                        placeholder="Your feedback"
                        rows={'5'}
                      />
                    </FormControl>
                    <FormControl id="submit" float="right">
                      <Button
                        isLoading={state.submitting}
                        type="submit"
                        bg="#3F4094"
                        color="#fff"
                        variant="solid"
                        _hover={{
                          backgroundColor: '#b8c1ec',
                          color: '#232946'
                        }}>
                        Send Message
                      </Button>
                    </FormControl>
                  </VStack>
                </form>
              ) : (
                <Flex
                  direction={'column'}
                  justifyContent={'center'}
                  alignItems="center">
                  <Icon
                    m={3}
                    color="#3F4094"
                    as={FiCheckCircle}
                    w={10}
                    h={10}
                  />
                  <Text>Thanks for submitting your feedback. Stay blessed</Text>
                </Flex>
              )}
            </Box>
          </Wrap>
        </Box>
      </Flex>
    </Container>
  );
}

export default Feedback;
