import { useEffect, useState } from 'react';
import { useAudioPlayer } from 'react-use-audio-player';
import {
  IconButton,
  Icon,
  HStack,
  Stack,
  Text,
  Image,
  Box,
  Container,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex
} from '@chakra-ui/react';
import {
  FiPlay,
  FiPause,
  FiVolume1,
  FiVolumeX,
  FiVolume2
} from 'react-icons/fi';
import { useReactiveVar } from '@apollo/client';
import { setCurrentlyPlaying } from '../../../lib/cache';
import moment from 'moment';

const AudioPlayer = () => {
  const [volumeValue, setVolumeValue] = useState(0.5);
  const nowPlaying = useReactiveVar(setCurrentlyPlaying);
  const { togglePlayPause, ready, loading, playing, volume } = useAudioPlayer({
    src: nowPlaying.url,
    html5: true,
    format: ['mp3'],
    autoplay: false,
    volume: volumeValue
  });

  if (!ready && !loading) return <div>No audio to play</div>;

  useEffect(() => {
    volume(volumeValue);
  }, [volumeValue]);

  return (
    <>
      <Box
        zIndex={99}
        pos="fixed"
        w={'full'}
        bottom={0}
        bg={'#3F4094'}
        color={'white'}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={3}
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 4, md: 4 }}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <HStack w={'full'} px={5}>
            <Image
              pos={{ base: 'relative', md: 'absolute' }}
              bottom={{ base: '0', md: '5' }}
              left={{ base: '0', md: '10' }}
              boxSize={{ base: '50px', md: '70px' }}
              objectFit="cover"
              src={
                nowPlaying?.channelBackdrop ||
                'https://i.ibb.co/WKNvjsP/dclogo.png'
              }
              border="1px solid white"
              rounded="md"
            />
            <Flex direction={'column'} w={{ base: 'full', md: '80%' }}>
              <Text
                w={{ base: '85%', md: 'full' }}
                fontWeight="400"
                lineHeight={1.3}
                isTruncated>
                {nowPlaying?.currentStream?.title ||
                  'Dream Center Internet Radio'}
              </Text>

              <Text fontWeight="light" fontSize="xs">
                {moment().format('Do MMM, YYYY')}
              </Text>
            </Flex>
          </HStack>
          <HStack width={'80%'}>
            <IconButton
              _focus={{
                border: '1px solid'
              }}
              colorScheme="blackAlpha"
              aria-label="Play Radio"
              size="lg"
              fontSize="20px"
              isRound={true}
              onClick={togglePlayPause}
              isLoading={loading}
              isDisabled={loading}>
              {playing ? <FiPause /> : <FiPlay />}
            </IconButton>

            <Stack
              width={'full'}
              direction={'row'}
              justifyContent="center"
              spacing={2}>
              {volumeValue === 0 ? (
                <Icon
                  aria-label="Reduce volume"
                  color={'#b8c1ec'}
                  as={FiVolumeX}
                  w={6}
                  h={6}
                />
              ) : (
                <Icon
                  aria-label="Reduce volume"
                  color={'#b8c1ec'}
                  as={FiVolume1}
                  w={6}
                  h={6}
                />
              )}

              <Slider
                w="50%"
                aria-label="volume slider"
                value={volumeValue}
                step={0.05}
                min={0}
                max={1}
                onChange={(v) => setVolumeValue(v)}>
                <SliderTrack bg="#232946">
                  <SliderFilledTrack bg="#b8c1ec" />
                </SliderTrack>
                <SliderThumb boxSize={2.5} />
              </Slider>
              <Icon
                aria-label="Increase volume"
                color={'#b8c1ec'}
                as={FiVolume2}
                w={6}
                h={6}
              />
            </Stack>
          </HStack>

          {/* <Stack direction={'row'} spacing={6}>
            <Button
              size="sm"
              aria-label="Share media"
              leftIcon={<FiShare2 />}
              colorScheme="blackAlpha"
              isDisabled={true}
              variant="solid">
              Share
            </Button>
          </Stack> */}
        </Container>
      </Box>
    </>
  );
};

export default AudioPlayer;
