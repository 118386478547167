import { SimpleGrid, Container, Box, Heading } from '@chakra-ui/react';
import FormCardWithPopup from './components/FormCardWithPopup';
import { formData } from './formData';

const Forms = () => {
  const cards = formData.map(({ cardTitle, cardDesc, typeformId }) => (
    <FormCardWithPopup
      key={typeformId}
      cardTitle={cardTitle}
      cardDesc={cardDesc}
      typeformId={typeformId}
    />
  ));

  return (
    <Box as={Container} maxW="7xl" mt={6} p={4}>
      <Heading color={'#b8c1ec'} fontWeight="400" pb={10} fontSize={'2xl'}>
        Thanks for your response. We love to hear you!
      </Heading>
      <SimpleGrid
        py={10}
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={{ base: 4, md: 5, lg: 10 }}>
        {cards}
      </SimpleGrid>
    </Box>
  );
};

export default Forms;
