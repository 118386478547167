import PropTypes from 'prop-types';
import { Layout, AudioPlayer } from '..';

const LayoutWithPlayer = ({ children }) => {
  
  return (
    <>
      <Layout>{children}</Layout>
      <AudioPlayer />
    </>
  );
};

export default LayoutWithPlayer;

LayoutWithPlayer.propTypes = {
  children: PropTypes.element.isRequired
};
