/* eslint-disable no-unused-vars */
import OnAirContainer from './container';
import { useQuery } from '@apollo/client';
import { CHANNEL_LIST_QUERY } from '../../../data/graphql';
import { setChannels, setCurrentlyPlaying } from '../../../lib/cache';
import { useEffect } from 'react';

function OnAir() {
  
  const { loading, data } = useQuery(CHANNEL_LIST_QUERY);
  
  useEffect(() => {
    if (data?.channels) {
      setChannels(data.channels);
      setCurrentlyPlaying(data.channels[0]);
    }
  }, [loading]);
  
  return (
    <>
      <main>
        <OnAirContainer />
      </main>
    </>
  );
}

export default OnAir;
