import gql from 'graphql-tag';

// query for all channels
export const CHANNEL_LIST_QUERY = gql`
  query ChannelListQuery {
    channels {
      _id
      channelID
      name
      url
      currentStream {
        author
        title
        venue
        viewers
        likes
        duration
        albumArt {
          image
        }
        eventDetails {
          name
          description
        }
        isRestricted
      }
      channelBackdrop
      isLive
      isDefault
    }
  }
`;

export const CHANNEL_LIST_ADDED_SUBSCRIPTION = gql`
  subscription ChannelListAddedSubscription {
    channelAdded {
      _id
      channelID
      name
      url
      currentStream {
        author
        title
        venue
        viewers
        likes
        duration
        albumArt {
          image
          video
        }
        eventDetails {
          name
          description
        }
        isRestricted
      }
      channelBackdrop
      isLive
      isDefault
    }
  }
`;
