export const formData = [
  {
    typeformId: 'gu7Dsqrz',
    cardTitle: 'Your first time?',
    cardDesc: `We are happy you have taken the first step in getting connected at
      Dream Centre. Your contact details will allow us to communicate
      helpful information to you.`
  },
  {
    typeformId: 'CPzyAcsr',
    cardTitle: 'Becoming Born Again?',
    cardDesc: `We are so glad you have made the decision to accept Jesus Christ
      as your personal Lord and Savior. This is the best and most
      important decision you have ever made!`
  }
];
