// @flow
import { InMemoryCache, makeVar } from '@apollo/client';

export const createCache = () =>
  new InMemoryCache({
    typePolicies: {
      UserSetting: {
        fields: {
          currentlyPlaying: {
            read() {
              return setCurrentlyPlaying();
            }
          }
        }
      }
    }
  });
const defaultChannel = {
  channelBackdrop: 'https://i.ibb.co/WKNvjsP/dclogo.png',
  channelID: 3,
  currentStream: {
    albumArt: {
      image: 'https://i.ibb.co/yByWkcr/2254f32596a422778a6ea98a.jpg'
    },
    author: ' ',
    duration: 0,
    eventDetails: {
      description: ' ',
      name: ' '
    },
    isRestricted: false,
    likes: null,
    title: 'Dream Center Internet Radio',
    venue: ' ',
    viewers: 0
  },
  isDefault: true,
  isLive: false,
  name: 'Primary',
  url: 'https://admin.dciradio.org/listen/primary/radio.mp3',
  _id: '609fe985a50a911b4cba1f16'
};

export const setChannels = makeVar([defaultChannel]);
export const setCurrentlyPlaying = makeVar(defaultChannel);
export const setVolumeVar = makeVar(1);
export const playPauseVar = makeVar(false);
export const setTranscript = makeVar('Starting transcript...');
//
// channelBackdrop: "https://i.ibb.co/WKNvjsP/dclogo.png"
// channelID: 3
// currentStream: {__typename: 'ChannelStreamInfo', author: ' ', title: 'Sola Areogun Ministries 24/7 Livestream | Faith Cometh By Hearing', venue: ' ', viewers: 0, …}
// isDefault: true
// isLive: false
// name: "Covenant"
// url: "https://demo.dciradio.org/radio/8000/radio.mp3"
// __typename: "ChannelInfo"
// _id: "60e3429c3e15252b48e97419"
