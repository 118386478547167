/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AudioPlayerProvider } from 'react-use-audio-player';
import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import customTheme from './chakraTheme.config';
import './index.css';
import App from './presentation/views';
// import reportWebVitals from './reportWebVitals';
import client from './lib/apolloClient';

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}>
    <ChakraProvider theme={customTheme}>
      <ApolloProvider client={client()}>
        <AudioPlayerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AudioPlayerProvider>
      </ApolloProvider>
    </ChakraProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
