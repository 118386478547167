import { Box, Heading, Text, Button, Icon } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Podcast = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Icon as={FiAlertCircle} boxSize={'70px'} color={'#b8c1ec'} />
      <Heading
        textTransform="uppercase"
        fontWeight="medium"
        fontSize={'2xl'}
        color={'#fffffe'}
        as="h2"
        size="xl"
        mt={6}
        mb={4}
        isTruncated>
        Coming Soon
      </Heading>
      <Text color={'#b8c1ec'} mt={6} mb={4}>
        Thanks for checking. Page is not ready
      </Text>
      <Button
        size="lg"
        as={Link}
        to="/"
        bg="#3F4094"
        color="#b8c1ec"
        variant="solid"
        _hover={{
          backgroundColor: '#b8c1ec',
          color: '#232946'
        }}>
        Go to Home
      </Button>
    </Box>
  );
};

export default Podcast;
