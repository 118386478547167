/* eslint-disable no-unused-vars */
import {
  SimpleGrid,
  Container,
  Box,
  Grid,
  VStack,
  GridItem,
  Heading
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { Card } from '../components';
import { Chat } from 'presentation/components';
import { setCurrentlyPlaying, setUser, setChannels } from 'lib/cache';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const OnAirContainer = () => {
  const nowPlaying = useReactiveVar(setCurrentlyPlaying);
  const channels = useReactiveVar(setChannels);
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();
  const [user_id, setUser] = useState(
    isAuthenticated
      ? user?.name?.split(/@| /)[0] || user?.nickname
      : `Overcomer-${(Math.random() + 1).toString(36).substring(3)}`
  );
  const [profile_pic, setPp] = useState(
    user?.picture || 'https://i.pravatar.cc/150'
  );

  useEffect(() => {
    if (user) {
      setUser(user?.name.split(/@| /)[0] || user?.nickname);
      setPp(user?.picture || 'https://i.pravatar.cc/150');
    }
  }, []);

  return (
    <>
      <Box as={Container} maxW="7xl" mt={6} p={4}>
        <Heading color={'#b8c1ec'} fontWeight="400" pb={10} fontSize={'3xl'}>
          Message Ongoing
        </Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 2fr)',
            sm: '1fr',
            lg: '1fr 1fr',
            md: '1fr 1fr'
          }}
          mb={12}
          gap={2}>
          <GridItem colSpan={1}>
            <VStack alignItems="flex-start" spacing="20px">
              <Card
                key={nowPlaying.channelID}
                stateChanger={setCurrentlyPlaying}
                data={nowPlaying}
                primary={true}
              />
            </VStack>
          </GridItem>
          <GridItem>
            {false && <Chat user={user_id} pp={profile_pic} />}
          </GridItem>
        </Grid>

        <Heading fontWeight="400" color={'#b8c1ec'} fontSize={'3xl'}>
          Discover
        </Heading>

        <SimpleGrid
          py={10}
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={{ base: 4, md: 5, lg: 10 }}>
          {channels.map((channel) => (
            <Card
              key={channel.channelID}
              data={channel}
              primary={nowPlaying.channelID === channel.channelID}
              stateChanger={setCurrentlyPlaying}
            />
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default OnAirContainer;
