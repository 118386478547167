import PropTypes from 'prop-types';
import { Box, Heading, Text, Stack, Button } from '@chakra-ui/react';
import { Slider } from 'react-typeform-embed';

function FormCardWithPopup(props) {
  const { cardTitle, cardDesc, typeformId } = props;
  return (
    <>
      <Box
        bg={'#b8c1ec'}
        boxShadow={'2xl'}
        rounded={'lg'}
        textAlign="left"
        color="#232950"
        minH={'full'}
        p={6}>
        <Stack spacing={6} align={'center'} justify={'flex-end'} mb={5}>
          <Heading fontSize={'20px'} fontWeight={500} mb={5}>
            {cardTitle}
          </Heading>
          <Text>{cardDesc}</Text>
          <Slider id={typeformId} size={60}>
            <Button
              width="200px"
              bg="#3F4094"
              color="#fff"
              variant="solid"
              rounded={'md'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg'
              }}>
              Fill form
            </Button>
          </Slider>
        </Stack>
      </Box>
    </>
  );
}

export default FormCardWithPopup;

FormCardWithPopup.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardDesc: PropTypes.string.isRequired,
  typeformId: PropTypes.string.isRequired
};
